import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import Message from './Component/Message';
import { BASE_URL, websocket } from './BaseUrl';
import Header from './Component/Header';
// import { BaseUrl } from '../utils/Token';

const Chat = () => {
    const [messages, setMessages] = useState([]);

    const { roomid } = useParams();
    const [input, setInput] = useState('');
    const chatContainerRef = useRef(null);
    const clientRef = useRef(null);

    const token = localStorage.getItem("token");
    const userid = "1";

    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const fileMessage = "text";

    const handleGet = () => {
        axios.get(`${BASE_URL}single_chat?room_id=${roomid}`, { headers })
            .then((res) => {
                setMessages(res.data.data?.reverse());
                console.log(res.data.data)
            })
            .catch((error) => {
                console.error('Error fetching messages:', error);
            });
    };

    useEffect(() => {
        handleGet();
    }, [roomid]);

    useEffect(() => {
        clientRef.current = new W3CWebSocket(`${websocket}`);

        clientRef.current.onopen = () => {
            console.log('WebSocket Client Connected');
            clientRef.current.send(
                JSON.stringify({
                    type: 'joinRoom',
                    room: roomid,
                })
            );
        };

        clientRef.current.onmessage = (message) => {
            const data = JSON.parse(message.data);
            setMessages((prevMessages) => [...prevMessages, data.data]);
        };

        clientRef.current.onerror = (error) => {
            console.error('WebSocket Error:', error);
        };

        return () => {
            if (clientRef.current) {
                clientRef.current.close();
            }
        };
    }, [roomid]);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    const sendMessage = async (e) => {
        e.preventDefault();
        if (input.trim()) {
            const msg = {
                room_id: roomid,
                sender: userid,
                type: "Admin",
                message: input,
                file_message: fileMessage
            };
            try {
                await axios.post(`${BASE_URL}single_chat`, msg, { headers });
                setInput('');
                clientRef.current.send(JSON.stringify(msg));
            } catch (error) {
                console.error('Error sending message:', error);
            }
        }
    };

    const [isSmallScreen, setIsSmallScreen] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <Header />
            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <a href={'/'} className='btn mb-3 bg-primary text-white'>
                                <i className="fa-solid fa-chevron-left"></i> Ticket List
                            </a>
                        </div>
                        <div className="col-md-6">
                            <div className="card flex-grow-1">
                                <div className="card-header text-center">
                                    <h2>Chat App</h2>
                                </div>
                                <div className="card-body d-flex flex-column">
                                    <div className="flex-grow-1 overflow-auto mb-3 chatcontent" ref={chatContainerRef}>
                                        {messages.map((msg, index) => (
                                            <Message
                                                key={index}
                                                msg={msg.message}
                                                type={msg.message_type}
                                                usertype={msg.type}
                                                sender={userid}
                                                createdat={msg.created_at}
                                                file_message={msg.file_message}
                                                reciver={msg?.sender}
                                            />
                                        ))}
                                    </div>
                                    <form onSubmit={sendMessage} className="d-flex">
                                        <input
                                            type="text"
                                            placeholder="Type a message..."
                                            value={input}
                                            onChange={(e) => setInput(e.target.value)}
                                            className="form-control me-2"
                                        />
                                        <button type="submit" className="btn bg-primary text-white">Send</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Chat;
