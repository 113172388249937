import React from 'react'
import logo from "../logo.png"

function Header() {
    return (
        <>
            <nav class="navbar navbar-expand-lg bg-white shadow mb-4 ">
                <div class="container-fluid ">
                    <div className="d-flex justify-content-center w-100">
                        <a class="navbar-brand" href="#"><img style={{ width: "200px" }} src={logo} className='img-fluid' alt="" /></a>
                    </div>


                </div>
            </nav>
        </>
    )
}

export default Header