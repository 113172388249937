import React from 'react';
import notfound from "../notfound.png"
import { BASE_URL } from '../BaseUrl';
const Message = ({ msg, sender, reciver, type, file_message, usertype }) => {

    console.log(usertype)


    return (
        <div className={`d-flex ${usertype == "Admin" ? 'justify-content-end' : 'justify-content-start'} mb-2`}>
            {msg && (
                <div
                    className={`alert ${usertype == "Admin" ? 'bg-success' : 'bg-primary'} p-2 text-white rounded-lg mb-0`}
                    style={{ maxWidth: '250px', wordWrap: 'break-word' }}
                >
                    {type == "text" ?
                        <>
                            <p className="mb-0">{msg}</p>

                        </>
                        :

                        <>

                            <img
                                src={`${BASE_URL}${file_message}`}
                                alt="alt"
                                className="object-contain " style={{ width: "200px" }}
                                onError={(e) => e.target.src = notfound}
                            />
                        </>}
                </div>
            )
            }
        </div >
    );
};

export default Message;
