import logo from './logo.svg';
import './App.css';
import Chat from './Chat';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TicketList from './TicketList';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/chat/:roomid' element={<Chat />} />
        <Route path='/' element={<TicketList />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
