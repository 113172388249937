import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BASE_URL } from './BaseUrl';
import { toast, ToastContainer } from 'react-toastify'; // Import toast for notifications
import Header from './Component/Header';

function TicketList() {
    const [data, setData] = useState([]);

    const handleGet = () => {
        axios.get(`${BASE_URL}ticket`)
            .then((res) => {
                console.log(res.data);
                setData(res.data.data?.reverse()); // Store the fetched data in the state
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };


    const handleChatClose = async (e, id) => {

        e.preventDefault();
        await ChatEnd(id)
        let requestdata = {
            is_chat_open: false,
            _id: id
        }
        axios.put(`${BASE_URL}ticket`, requestdata).then((res) => {
            console.log(res)
            if (res.data.status == "OK") {

                handleGet()

            }

        })
    }

    useEffect(() => {
        handleGet();
    }, []);


    const handleclosed = async (e, id) => {
        e.preventDefault();
        await sendMessage(id)
        let requestdata = {
            status: "Closed",
            closed_by: "1",
            _id: id
        }
        axios.put(`${BASE_URL}ticket`, requestdata).then((res) => {

            if (res.data.status == "OK") {

                handleGet()

            }
        })

    }


    useEffect(() => {
        // Fetch data initially
        handleGet();

        // Set up an interval to fetch data every 1 minute (60000 ms)
        const intervalId = setInterval(handleGet, 60000);


        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);


    const sendMessage = async (id) => {
        const msg = {
            room_id: id,
            sender: "Admin",
            type: "Admin",
            message: "This chat session has now been closed. If you have another issue or need further assistance, please raise a new ticket. Thank you for your understanding.",
            file_message: "text"
        };
        try {
            await axios.post(`${BASE_URL}single_chat`, msg);


        } catch (error) {
            console.error('Error sending message:', error);
        }

    };

    const ChatEnd = async (id) => {
        const msg = {
            room_id: id,
            sender: "Admin",
            type: "Admin",
            message: "This chat session has ended. For any further issues or assistance, please open a new ticket. Thank you for your understanding.",
            file_message: "text"
        };
        try {
            await axios.post(`${BASE_URL}single_chat`, msg);


        } catch (error) {
            console.error('Error sending message:', error);
        }

    };

    const [isSmallScreen, setIsSmallScreen] = useState(false)
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 768);
        };

        // Initialize the state based on the initial window size
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const [searchInput, setSearchInput] = useState("");

    const handleSearchInput = (e) => {
        setSearchInput(e.target.value);
    };

    const filteredTickets = data.filter(
        (item) =>
            item.status.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.priority.toLowerCase().includes(searchInput.toLowerCase()) || item.order_id.includes(searchInput) || item.type.toLowerCase().includes(searchInput.toLowerCase())
    );



    return (
        <>
            <Header />
            <div className="container mt-4">
                <ToastContainer />
                <div className="row mt-5">
                    <div className="col-md-6">
                        <div className="">
                            <h2 className="mb-4">Ticket List</h2>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-group bg-white shadow rounded-3 mb-3">
                            <input
                                type="search"
                                className="form-control border-0 rounded-3" style={{ paddingLeft: "30px" }}
                                placeholder="Search by Status, Priority, or Order Id and Type"

                                value={searchInput}
                                onChange={handleSearchInput}
                            />
                            <span className="" style={{ position: "absolute", left: "10px", top: "6px", zIndex: 100 }}>
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </span>
                        </div>
                    </div>
                </div>
                {isSmallScreen ? <div className="tablefont table-responsive">

                    {
                        filteredTickets.length > 0 ? filteredTickets.map((item) => {
                            return (
                                <div key={item._id} className="bg-white shadow rounded-3 p-3 my-3">
                                    <div className="d-flex justify-content-between align-items-center mb-2">
                                        <h6 className="mb-0">
                                            {item.order_id}
                                        </h6>
                                        <div className="d-flex align-items-center gap-2">
                                            <span className={`badge ${item.priority == 'High' ? 'bg-danger' : item.priority === 'Medium' ? 'bg-warning' : 'bg-success'}`}>
                                                {item.priority}
                                            </span>
                                            <span className={`badge ${item.status == 'Pending' ? 'bg-info' : item.status == 'Closed' ? 'bg-secondary' : 'bg-success'}`}>
                                                {item.status}
                                            </span>
                                        </div>
                                    </div>
                                    <h5 className="mt-2">{item.issue}</h5>
                                    <div className="d-flex align-items-center gap-3 mt-3">
                                        <div>
                                            {item.is_chat_open ? (
                                                <a href={`/chat/${item._id}`} className="btn bg-primary text-white">Chat</a>
                                            ) : (
                                                <span className="badge bg-secondary" onClick={() => toast.error("Chat is not available at this time.")}>
                                                    No Chat Available
                                                </span>
                                            )}
                                        </div>
                                        <div>
                                            {item.is_chat_open ? (
                                                <button onClick={(e) => handleChatClose(e, item._id)} className="btn bg-success text-white">End Chat</button>
                                            ) : (
                                                <span className="badge bg-secondary" onClick={() => toast.error("User is Offline")}>
                                                    Offline
                                                </span>
                                            )}
                                        </div>
                                        <div>
                                            {item.status === "Pending" ? (
                                                <button onClick={(e) => handleclosed(e, item._id)} className="btn bg-primary text-white">Close Ticket</button>
                                            ) : (
                                                <span className="badge bg-secondary" onClick={() => toast.error("Ticket is already Closed")}>
                                                    Ticket is Closed
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <h6 className="text-secondary">{(item.created_at.substring(0, 10))}</h6>
                                    </div>
                                </div>
                            );
                        })
                            : <>
                                <div className="text-center my-4">
                                    <h6 className="fw-bold">Ticket is Not Available</h6>
                                </div>
                            </>}


                </div> : <div className="table-responsive">
                    <table className="table table-bordered table-hover">
                        <thead className="thead-dark">
                            <tr>
                                <th>SR. Number</th>
                                <th>Order ID</th>
                                <th>Type</th>
                                <th>From User</th>
                                <th>Issue</th>
                                <th>Status</th>
                                <th>Priority</th>

                                <th>Chat </th>
                                <th>Chat Status </th>
                                <th>Ticket Status </th>

                                <th>Created At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredTickets.length > 0 ? (
                                filteredTickets.map((ticket, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{ticket.order_id}</td>
                                        <td>{ticket.type}</td>
                                        <td>{ticket.from_user}</td>
                                        <td>{ticket.issue}</td>

                                        <td>{ticket.status}</td>
                                        <td>{ticket.priority}</td>
                                        <td>
                                            {ticket.is_chat_open ? (
                                                <button
                                                    onClick={(e) => handleChatClose(e, ticket._id)}
                                                    className="btn bg-success text-white"
                                                >
                                                    End Chat
                                                </button>
                                            ) : (
                                                <span
                                                    className="badge bg-secondary"
                                                    onClick={() => toast.error("User is Offline")}
                                                >
                                                    Offline
                                                </span>
                                            )}
                                        </td>
                                        <td>

                                            {ticket.is_chat_open ? (
                                                <a
                                                    href={`/chat/${ticket._id}`}
                                                    className="btn bg-primary text-white ml-2"
                                                >
                                                    Chat
                                                </a>
                                            ) : (
                                                <span
                                                    className="badge bg-secondary ml-2"
                                                    onClick={() => toast.error("Chat is not available at this time.")}
                                                >
                                                    No Chat Available
                                                </span>
                                            )}
                                        </td>
                                        <td>
                                            {ticket.status === "Pending" ? (
                                                <button
                                                    onClick={(e) => handleclosed(e, ticket._id)}
                                                    className="btn bg-primary p-1 text-white" style={{ fontSize: "12px" }}
                                                >
                                                    Close Ticket
                                                </button>
                                            ) : (
                                                <span className="badge bg-secondary" onClick={(e) => toast.error("Ticket is already  Closed")}>
                                                    Ticket is Closed
                                                </span>
                                            )}
                                        </td>
                                        <td>{ticket.created_at.substring(0, 10)}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7" className="text-center">No tickets found</td> {/* Adjust colspan to 7 */}
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>}

            </div>
        </>
    );
}

export default TicketList;
